import type { NotificationProps } from "@mantine/notifications";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";

type ShowErrorNotificationParams = NotificationProps;

/**
 * @description 99% of the time you should use fail() instead. If using this directly, make sure you have a good reason for not sending the error to Sentry.
 */
function showErrorNotification(params: ShowErrorNotificationParams) {
  const { title = "Error", message = "", ...props } = params;

  showNotification({
    autoClose: false,
    color: "red",
    icon: <IconX color={EnderThemeColorEnum.WHITE} />,
    message,
    title,
    ...props,
  });
}

export { showErrorNotification };
export type { ShowErrorNotificationParams };
