import { Array as A, Predicate as P } from "effect";

import { NULL } from "@ender/shared/constants/general";
import { randomEnderId } from "@ender/shared/core";
import type { EmptyObject } from "@ender/shared/types/general";
import { EnderError } from "@ender/shared/utils/error";

class RestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "RestError";
  }
}

type RestMethods = "get" | "post" | "put" | "patch" | "delete";

type ApiData = FormData | object | string | null | undefined;

type Options = Partial<
  Omit<Request, "method" | "body" | "credentials" | "headers">
> & {
  download?: boolean;
  headers?: HeadersInit;
};
/**
 * @deprecated Please use `Options` instead.
 */
type AuthorOptions = Options;

type ApiMethod = <T = unknown>(
  url: string,
  data?: ApiData,
  options?: Options,
) => Promise<T>;

type Rest = {
  _: EmptyObject;
} & Record<RestMethods, ApiMethod>;

const methodsArray = ["get", "post", "put", "patch", "delete"] as const;

const rest: Rest = {
  _: Object.freeze({}),
} as Rest;

// function apiPath(path: string): string {
//   // Absolute URLs are required for mock service workers
//   // For more information, see: https://github.com/mswjs/msw/issues/1625
//   const url = new URL(`/api${path}`, globalThis.location.origin);
//   return url.toString();
// }

function apiPath(path: string): string {
  // Absolute URLs are required for mock service workers
  // For more information, see: https://github.com/mswjs/msw/issues/1625
  const url = new URL(
    `/api/${path.replace(/^\//, "")}`,
    globalThis.location.origin,
  );
  return url.toString();
}

for (const method of methodsArray) {
  rest[method] = async (url: string, data: ApiData, options: Options = {}) => {
    let fullUrl = url.startsWith("http") ? url : apiPath(url);

    const headers = new Headers(options.headers);
    headers.append("X-ENDER-TRACE-ID", randomEnderId());

    let body = null;
    if (method === "get" && P.isNotNullable(data)) {
      if (!P.isRecord(data) && !P.isString(data)) {
        throw new RestError(
          `GET method only supports objects and strings. URL: ${fullUrl}`,
        );
      }

      let searchParams: URLSearchParams = new URLSearchParams();
      if (P.isRecord(data)) {
        searchParams = new URLSearchParams(
          Object.entries(data)
            .filter(([, value]) => !P.isNullable(value))
            .map(([key, value]) => {
              const serializedValue =
                P.isRecord(value) || A.isArray(value)
                  ? // JSON.stringify Objects & Arrays
                    JSON.stringify(value)
                  : // toString every other value
                    `${value}`;
              return [key, serializedValue];
            }),
        );
      } else if (P.isString(data)) {
        searchParams = new URLSearchParams(data);
      }

      // iOS Safari does not support URLSearchParams().size
      const hasSearchParams = Array.from(searchParams).length > 0;
      fullUrl = hasSearchParams
        ? `${fullUrl}?${searchParams.toString()}`
        : fullUrl;
    } else if (P.isNotNullable(data)) {
      body = data instanceof FormData ? data : JSON.stringify(data);
    }

    const response = await fetch(fullUrl, {
      body,
      headers,
      method: method.toUpperCase(),
      ...options,
    }).catch((e) => {
      let errorMessage = e.message;
      if (
        errorMessage === "Failed to fetch" ||
        errorMessage === "NetworkError when attempting to fetch resource."
      ) {
        errorMessage =
          "Ender is currently unavailable. Please try again later.";
      }
      throw new RestError(errorMessage);
    });

    const text = await response.clone().text();

    if (response.ok) {
      if (text.length > 0) {
        if (response.headers.get("content-type") === "application/json") {
          // assume all successful application/json bodies are actually json
          return Promise.resolve(response.clone().json());
        }

        if (response.headers.has("content-disposition") || options.download) {
          //if the regex is a match, the first capture group represents the filename string provided
          const regExpFilename = /filename="(.*)"/;
          //the output of `regExpFilename` may be `nullish` if no match was found, and in this case `filename` should also be nullish
          const filename = regExpFilename.exec(
            response.headers.get("content-disposition") ?? "",
          )?.[1];
          return Promise.resolve({
            blob: response.clone().blob(),
            filename,
            response,
          });
        }

        throw new RestError("Invalid JSON or file");
      }

      return Promise.resolve(NULL);
    }

    if (text.length > 0) {
      if (response.headers.get("content-type")?.includes("application/json")) {
        const json = await response.clone().json();
        // Fastify returns errors in the format: { error: "Internal Server Error" }
        if (json.error) {
          let errorMessage = json.error;
          if (errorMessage === "Internal Server Error") {
            errorMessage =
              "Ender is currently unavailable. Please try again later.";
          }
          throw new RestError(errorMessage);
        }

        // start temporary
        // fix for handle-fetch-with-warnings
        if (json.errors || json.warnings) {
          throw new EnderError({ json, response });
        }
        // end temporary

        throw new RestError("Invalid JSON");
      }

      throw new RestError(text);
    }

    throw new RestError(
      "Ender is currently unavailable. Please try again later.",
    );
  };
}

export { apiPath, rest };
export type { AuthorOptions, Options };
